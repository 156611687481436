<template>
  <div>
    <flixLoader v-if="!loaded"/>
    <div class="flix-list-group" v-else>
      <p><i>Hinweis: Hier können Sie die angelegten Organisationen verwalten und die vergebenen Rollen (Rechte) ändern.</i></p>
      <div class="flix-list-group-item" v-for="(org, index) in organisations" :key="org.ID">
      <status :status="org.status"/> <b>{{ org.name }}</b>
        <div class="flix-pull-right" v-if="org.role === 'admin'">
          <router-link :to="{name: 'dashboardPermissions', params: { part: 'organisations', action: org.ID }}" style="display: inline-block" class="flix-html-a flix-badge">
            <flixIcon id="wrench" /> {{ $tc('message.settings', 2) }}
          </router-link>
          <div class="flix-pull-right">
            <delete style="margin-left: 10px" :callback="function () { setDelete(index) }"></delete>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    status () { return import('./status.vue') },
    delete () { return import('../../accounts/list/delete') }
  },
  props: {},
  data () {
    return {
      loaded: false,
      variables: this.$getUserVariables(),
      organisations: false
    }
  },
  computed: {

  },
  mounted () {
    this.getOrganisations()
  },
  methods: {
    setDelete (index) {
      this.$flix_post({
        url: 'organisation/delete',
        data: {
          user: this.$store.getters.user.md5_id,
          organisation: this.organisations[index].ID
        },
        callback: function (ret) { if (!ret.data[0]) { return false } this.organisations.splice(index, 1) }.bind(this)
      })
    },
    setOrganisation (ret) {
      this.loaded = true
      this.organisations = ret
    },
    getOrganisations () {
      this.$store.dispatch('getOrganisations', this.setOrganisation)
    }
  }
}
</script>
<style lang="sass" scoped>
</style>
